// Get if GLOBALE is active or not
var isGlobaleActive =
    typeof GEP != "undefined" &&
    typeof GEP.isOperatedByGlobale != "undefined" &&
    GEP.isOperatedByGlobale !== false;

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: { lang: locale, url: uri },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }
                location.href = data + force;
            }
        },
    });
}

function zoomImg(slide) {
    $(".zoom_main_wrapper").css("opacity", 0);

    window.setTimeout(function () {
        new Swiper("#thumbs_scroller", {
            slidesPerView: 1,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            initialSlide: slide - 1,
            navigation: {
                nextEl: ".thumbsNext",
                prevEl: ".thumbsPrev",
            },
        });
    }, 100);

    window.setTimeout(function () {
        $(".zoom_main_wrapper").animate({ opacity: 1 }, 650);
    }, 750);

    openMultiShad("zoomBox");
    $("#shad").addClass("actif");

    if ($("#shad").hasClass("actif")) {
        $("#shad").on("click", function () {
            closeMultiShad("zoomBox");
        });
    }

    // Hidde overflow to prevent double scroll on page on zoom
    if ($("body.product_page").length) {
        if ($("#zoomBox").hasClass("actif")) {
            $("body.product_page").toggleClass("no_hoverflow");
        }
        $("#shad").on("click", function () {
            $("body.product_page").removeClass("no_hoverflow");
        });
    }
}

/*
 * Display sizes dropdown on "select a size" FP button
 * */
$(document).on("click touch", "#fpWrapperBtn.noSize", function () {
    $("#ligne_pointure").click();
});

/*
 * Add loader on basket rollover btn
 * */
$(document).on("click touch", ".wrap_tot_panier .button", function () {
    $(this).next(".loader").addClass("loading");
});

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    if (
        (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) ||
        (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false)
    ) {
        data.warehouse_only = 1;
    }

    var elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result) {
                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        $("#btn_resa_shop").show();
                        $("#bloc_add_alert").hide();
                        size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                            '[value="' + size_id + '"]'
                        );
                        is_disabled = !sizes_tab[size_id];
                        if (is_disabled) {
                            size_box.addClass("disabled");
                        } else {
                            size_box.removeClass("disabled");
                        }
                    } else {
                        $("#btn_resa_shop").hide();
                        $("#bloc_add_alert").show();
                    }
                }
            }
        }
    });
}

// permet de faire clignoter si la taille n'as pas etait choisi
function FaireClignoterImage() {
    $("#ligne_pointure").addClass("image-clignote");
    window.setTimeout(function () {
        $("#ligne_pointure").removeClass("image-clignote");
        $("#ligne_pointure").addClass("actif");
        $("#ligne_pointure .choices_list").addClass("opened");
    }, 2000);
}

function closeSearch() {
    $(".wrap_search_field").fadeOut();
    $("body").removeClass("search");
    $("#trigger_recherche").css("opacity", "1");
    $("#shad_menu").removeClass("actif");
    if ($(window).scrollTop() <= 0) {
        $("#site_head_wrap").removeClass("sticky");
    }
}

function closeSearchRecap() {
    let input_search = document.getElementById("search_home");

    input_search.value = "";
    input_search.focus();
}

function searchCategoryFilter() {
    // for each .sub_cat_name, check if it has the .current class, if so, save its index to use as the initial slide
    let currentSlide = 0;
    $(".sub_cat_name").each(function (index) {
        if ($(this).hasClass("current")) {
            currentSlide = index;
        }
    });
    
    var catFilterSwiper = new Swiper(".sub_categ_wrapper #slideCateg", {
        roundLengths: true,
        setWrapperSize: true,
        slidesPerView: "auto",
        initialSlide: currentSlide,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
    }); 
}

$(function () {
    $("#resetPass .w-submit-button").on("click", function () {
        $("#subtitlePassword").animate(
            {
                opacity: 0,
            },
            600,
            function () {
                $("#subtitlePassword").css("display", "none");
            }
        );
    });
    /* Avoid page from repositionning during bandeau header cookie check */
    if ($("body.category").length) {
        window.setTimeout(function () {
            $("#site_global_wrap").addClass("appear");
        }, 500);
    }

    if ($("body.giftcard_index").length) {
        window.setTimeout(function () {
            $("#site_global_wrap").addClass("appear");
        }, 500);
    }

    $(document).on("click touch", ".js-close-filter", function () {
        $("#rayon_sticky_block").removeClass("show");
        $(".shad").removeClass("actif");
        $("#trigger_filtre").removeClass("triggered");
    });

    $("#theme_global .theme").on("click", function () {
        $("html, body").animate({ scrollTop: 400 }, 500);
        $(".reassurance").addClass("changeBottom");
    });

    $("#addToWishlistButton").on("click", function () {
        if ($("#bloc_availability").hasClass("inactiv")) {
            FaireClignoterImage();
        }
    });

    $("#bloc_availability").on("click", function () {
        if ($("#bloc_availability").hasClass("inactiv")) {
            FaireClignoterImage();
        }
    });

    $(".characts_8").on("hover", function () {
        $(".attribute_8").toggleClass("active");
    });
    $(".characts_9").on("hover", function () {
        $(".attribute_9").toggleClass("active");
    });
    $(".characts_10").on("hover", function () {
        $(".attribute_10").toggleClass("active");
    });
    $(".characts_11").on("hover", function () {
        $(".attribute_11").toggleClass("active");
    });
    $(".characts_12").on("hover", function () {
        $(".attribute_12").toggleClass("active");
    });
    $(".characts_13").on("hover", function () {
        $(".attribute_13").toggleClass("active");
    });

    $(".w-input-element").on("click", function () {
        $(this).removeClass("inputErr");
    });

    if ($(".promo_swiper .swiper-slide").length > 0) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: "auto",
            speed: 1500,
        });
    }

    if ($("#slideCateg .swiper-slide").length) {
        searchCategoryFilter();
    }

    $("#trigger_recherche").on("click touch", function () {
        $(".wrap_search_field").fadeIn();
        $("body").addClass("search");
        $("#site_head_wrap").addClass("sticky");
        $("#trigger_recherche").css("opacity", "0");
        $("#shad_menu").addClass("actif");
    });

    $(".close_search").on("click", function () {
        let input_search = document.getElementById("search_home");

        if (input_search.value === "") {
            closeSearch();
        } else {
            input_search.value = "";
            input_search.focus();
        }

        if ($(window).scrollTop() <= 0) {
            $("#site_head_wrap").removeClass("sticky");
        }
    });

    $("#shad_menu").on("click", function () {
        $(".wrap_search_field").fadeOut();
        $("body").removeClass("search");
        $("#trigger_recherche").css("opacity", "1");
        $("#shad_menu").removeClass("actif");
        if ($(window).scrollTop() <= 0) {
            $("#site_head_wrap").removeClass("sticky");
        }
    });

    /**
     * Back to top event
     */
    backToTop = function () {
        var scrollTrigger = 100;
        var scrollTop = $(window).scrollTop();
        var copyrightHeight = $("#copyright").innerHeight();
        var copyrightOffset = $("#copyright").offset();

        var categoryPage = $("body.category");

        if (categoryPage.length) {
            if (
                scrollTop > scrollTrigger &&
                scrollTop < copyrightOffset.top - copyrightHeight
            ) {
                $("#scroll_to_top").addClass("show");
            } else {
                $("#scroll_to_top").removeClass("show");
            }
        }
    };

    backToTop();

    $(window).on("scroll", function () {
        backToTop();
    });

    $("#scroll_to_top").on("click", function (e) {
        e.preventDefault();
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            700
        );
    });

    if ($(".cartAssociations").length) {
        updateCartElements();
    }

    moveProductOutOfStock();

    new Swiper("#sliderHomeInsta", {
        slideToClickedSlide: true,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        nextButton: ".swiper-button-next.instaNext",
        prevButton: ".swiper-button-prev.instaPrev",
    });

    /**
     * Category SEO txt read more
     */
    if ($(".txt.trimed").length) {
        var trimedTxt = $(".txt.trimed");
        var read_more = $(".read_more");
        var read_less = $(".read_less");
        var heightToToggle = trimedTxt.height();
        trimedTxt.css("height", "75px");
        read_more.on("click", function () {
            trimedTxt.addClass("cache");
            trimedTxt.animate({ height: heightToToggle + 40 }, 500);
            read_more.addClass("cache");
            read_less.removeClass("cache");
        });
        read_less.on("click", function () {
            trimedTxt.removeClass("cache");
            trimedTxt.animate({ height: "75px" }, 500);
            read_more.removeClass("cache");
            read_less.addClass("cache");
        });
    }

    // INJECT FLOWBOX

    if ($('body.product_page').length) {
        let intervalID = setInterval(addTitleToFlowbox, 100);
        let counter = 0;
        const maxRepetitions = 17;
        function addTitleToFlowbox() {
            if ($('#flowbox-container').length && !$('#flowbox-container').is(':empty')) {
                /* #flowbox-container found, stop repetitions */
                $(".flowBoxBloc .wrapper").addClass("actif");
                clearInterval(intervalID);
            } else if (counter >= maxRepetitions) {
                /* Stop repetitions after 17 times */
                $(".flowBoxBloc").remove()
                clearInterval(intervalID);
            }
            ++counter;
        }
    }




    if ($(".videos-module .swiper").length) {
        new Swiper(".videos-module .swiper", {
            pagination: {
                el: ".videos-module-pagination",
                type: "bullets",
                clickable: true,
            },
        });
    }

    $(".date_filter_title").on("click", function () {
        $("#date_filter_wrapper").slideToggle();
    });

    $(".block-btn-filter .button").on("click", function () {
        $(this).next().addClass('loading');
        setTimeout(function () {
            $('.loader').removeClass('loading');
        }, 2000);
    })
    
    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 300);
        }
    });

//To style the footer after submitting the client review form
    if ($('body.avis_clients').length && $('.titre_contact_submitted').length) {
        // Check if the screen size is between 1440 and 1920 pixels
        var screenWidth = $(window).width();
        if (screenWidth >= 1440 && screenWidth <= 1920) {
            $('body.avis_clients #copyright.footer').css({
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
            });
        } 
    }
});

$(".sizesList label").on("click", function () {
    $(".choices_list").removeClass("opened");
});

function initProductSizeFieldset(parent) {
    /**
     Function to simulate the behavior of a select
     */
    $(parent + " .productSizeFieldset").on("click touch", function () {
        var sizesList = $(".sizesList", $(this));
        var shadSelect = $(".shadSelect");

        sizesList.toggleClass("opened");
        $(this).toggleClass("actif");

        if (!sizesList.hasClass("opened")) {
            $(this).removeClass("actif");
        }

        shadSelect.on("click touch", function () {
            sizesList.removeClass("opened");
            $(this).removeClass("actif");
            $(parent + " .productSizeFieldset").removeClass("actif");
        });
    });


}

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

/** Close Popup know more connexion page */
function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({ opacity: 0 }, 350, function () {
        $shad.css({ display: "none", opacity: "" }).data("opened_lb", 0);
    });
    $modbox.css({ display: "none" });
}

$(".menu_itm_hover")
    .on("mouseenter touchStart", function () {
        $("#shad_menu").addClass("actif");
    })
    .on("mouseleave", function () {
        $("#shad_menu").removeClass("actif");
    });

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartAppear() {
    var show_top_cart_parent = $("#show_top_cart");
    var count_bask = $("#cart_top .cart").attr("data-cart");

    if ($("#show_top_cart .gondoleInterest .swiper-slide").length > 1) {
        new Swiper("#show_top_cart .gondoleInterest", {
            direction: "vertical",
            slidesPerView: "auto",
            slidesPerGroup: 1,
            scrollbar: {
                el: ".swiper-scrollbar",
            },
            mousewheel: true,
        });
    }

    /* Closing look lightbox if displayed */
    if ($(".lookLightbox.actif").length) {
        $(".lookLightbox").removeClass("actif");
    }

    if ($("body.cart").length) {
        $(".block_top_item.my_basket a").addClass("shop_b");
        show_top_cart_parent.addClass("hover");
        $("#shad_menu").addClass("actif");
    } else {
        show_top_cart_parent.addClass("hover");
        $("#shad_menu").addClass("actif");
        $(".cart ").removeClass("shop");
        $(".cart ").addClass("shop_b");
    }
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartDesappear() {
    var show_top_cart_parent = $("#show_top_cart");
    if ($("body.cart").length) {
        $(".block_top_item.my_basket a").addClass("shop_b");
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");
        $("#shad_menu").removeClass("actif");
    } else {
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");
        $("#shad_menu").removeClass("actif");
        $(".cart ").removeClass("shop_b");
        $(".cart ").addClass("shop");
    }
}

$("#cart_top").on("mouseenter", function () {
    topCartAppear();
});

$("#cart_top").on("mouseleave", function () {
    topCartDesappear();
});

/**
 * Switch icons color on rollover + display sahd
 */
if ($("body.customer").length) {
    $(".block_top_item.my_account a").addClass("user_b");
}

$(".block_top_item.my_account")
    .on("mouseenter", function () {
        $(".my_account a").removeClass("user");
        $(".my_account a").addClass("user_b");

        if ($(".is_logged").length) {
            $("#shad_menu ").addClass("actif");
        }
    })
    .on("mouseleave", function () {
        $(".my_account a").removeClass("user_b");
        $(".my_account a").addClass("user");

        if ($(".is_logged").length) {
            $("#shad_menu ").removeClass("actif");
        }
    });

if ($("body.cart").length) {
    $(".block_top_item.my_basket a").addClass("shop_b");
}

if ($("body.wishlist").length) {
    $(".block_top_item.whislist a").addClass("heart_b");
} else {
    $(".block_top_item.whislist")
        .on("mouseenter", function () {
            $(".whislist a").removeClass("heart");
            $(".whislist a").addClass("heart_b");
        })
        .on("mouseleave", function () {
            $(".whislist a").removeClass("heart_b");
            $(".whislist a").addClass("heart");
        });
}

$("#addToWishlistButton").on("mouseenter", function () {
    $(this).removeClass("heart");
    $(this).addClass("heart_b");
});

if ($("body.store_locator").length) {
    $(".storeloc a").addClass("search_b");
} else {
    $(".storeloc a").on("mouseenter", function () {
        $(this).removeClass("search");
        $(this).addClass("search_b");
    });

    $(".storeloc a").on("mouseleave", function () {
        $(this).removeClass("search_b");
        $(this).addClass("search");
    });
}

$("#addToWishlistButton").on("mouseleave", function () {
    if (!$(this).hasClass("existToWishlistButton")) {
        $(this).removeClass("heart_b");
        $(this).addClass("heart");
    }
});

$("#btn_add_cart").on('click', function () {
    setTimeout(function () {
        $('.loader').removeClass('loading');
    }, 2500)
})

$("#zoomBox img").on("click", function () {
    closeMultiShad("zoomBox");
});





$(window).scroll(function () {
    if ($(".bandeauMenuAffix").length) {
        if ($(document).scrollTop() > 0) {
            $(".hasBando").removeClass("hasBando");
            $("body").addClass("bandoCollapsed");
        } else {
            $("body").addClass("hasBando");
            $(".bandoCollapsed").removeClass("bandoCollapsed");
        }
    }
});

$("#top_wrapper")
    .on("mouseenter", function () {
        $("#site_head_wrap").addClass("sticky");
    })
    .on("mouseleave", function () {
        if ($(document).scrollTop() === 0 && !$(".wrap_search_field").is(":visible")) {
            $("#site_head_wrap").removeClass("sticky");
        }
    });

$(".my_account").on("mouseenter", function () {
    $(this).children(".connexion_top").addClass("hover");
});
$(".my_account").on("mouseleave", function () {
    $(this).children(".connexion_top").removeClass("hover");
});

$(".showAmountBtn").on("click", function () {
    $(".pageGiftCard__main--amountinfos").addClass("actif");
    $("#shad").addClass("actif");
});

$(".pageGiftCard__main--amountinfos .close").on("click", function () {
    $(".pageGiftCard__main--amountinfos").removeClass("actif");
    $("#shad").removeClass("actif");
});

$(".pageGiftCard__main--amountinfos .return").on("click", function () {
    $(".pageGiftCard__main--amountinfos").removeClass("actif");
    $("#shad").removeClass("actif");
});

/**
 * Bank thanks create account button loader
 */
$(document).on("click touch", ".bankThanksBtn", function () {
    $(this).addClass("loading");
});

/* ------------------------------ HOME ------------------------------ */

$(function () {
    // Preheader
    window.setTimeout(function () {
        if ($(".bandeau_header .swiper-slide").length > 1) {
            preheaderSwiper = new Swiper(".bandeau_header .swiper-container", {
                slidesPerView: 1,
                speed: 1500,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                autoplayDisableOnInteraction: false,
                loop: true,
            });
        }

        if ($(".bandeau_header").length) {
            $("body").addClass("hasBando");
        }
    }, 1000);

    /** COVER MODULE */
    if ($(".sliderMainHome").length) {
        var homeSwiper = new Swiper(".sliderMainHome", {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 1,
            pagination: {
                el: ".cover-module-pagination",
                clickable: true,
            },
            loop: true,
            autoplay: {
                delay: 5000,
            },
        });
        // Stop autoplay on mouse hover
        $(".sliderMainHome").on("mouseenter", function (e) {
            homeSwiper.autoplay.stop();
        });
        $(".sliderMainHome").on("mouseleave", function (e) {
            homeSwiper.autoplay.start();
        });
    }

    /** TG MODULE */
    if ($(".tg-module .slider").length) {
        // iterate through all swipers with the same id
        $(".tg-module .slider").each(function (index) {
            var $this = $(this);
            $this.addClass("s-" + index);
            $this
                .parent()
                .find(".tg-module-pagination")
                .addClass("pagination-" + index);
            $this
                .parent()
                .find(".tg-module-pagination")
                .addClass("tg-module-pagination" + index);
            var tgSwiper = new Swiper(".s-" + index, {
                slidesPerView: 3,
                slidesPerGroup: 3,
                observeParents: true,
                observer: true,
                uniqueNavElements: false,
                pagination: {
                    el: ".tg-module-pagination" + index,
                    clickable: true,
                    type: "bullets",
                },
                breakpoints: {
                    1025: {
                        slidesPerView: 4,
                    },
                },
            });
        });
    }

    /** SOCIAL MODULE */
    if ($("#sliderHomeInsta").length) {
        new Swiper("#sliderHomeInsta", {
            slidesPerView: 4.5,
            observeParents: true,
            observer: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

    initFPCharacts();

    // Store detail image resizing on small screens
    var storeImageWrapper = $(".satellite_store_details .store_img");
    var storeDetailImg = $(".left-side-img");

    if (storeDetailImg) {
        var storeDetailImgUrl = storeDetailImg.attr("src");
        storeImageWrapper.css("--image-url", "url(" + storeDetailImgUrl + ")");
    }

    /* ------------------------------ CONTACT FAQ ------------------------------ */
    var contactFaqWrapper = $(".contact_faq #block_img");
    var contactFaqImg = $(".contact_faq_img");

    if (contactFaqImg) {
        var contactFaqImgImgUrl = contactFaqImg.attr("src");
        contactFaqWrapper.css(
            "--contact-faq-image-url",
            "url(" + contactFaqImgImgUrl + ")"
        );
    }

    /* ------------------------------MODULE LOOKBOOK PIN ------------------------------ */
    var tags = $(".cms_page_content .tag");
    tags.each(function () {
        var tag = $(this);
        var tagPrice = tag.find(".imgWrapper .item_price");
        var tagItemDescription = tag.find(".block_lnk .wrapper_description");
        tagPrice.insertAfter(tagItemDescription);
    });

    if ($(".product_page").length) {
        initTaille();
        rayon_sticky_block();
        swiperAssociation();
        swiperArticlesVue();
        floatRightBlock();
        initProductSizeFieldset(".product_main_wrapper");
    }

    var strongElement = $(
        "body.newsletter .content_newsletter .optin-select-line .multiline strong"
    );
    strongElement.replaceWith(function () {
        return $("<div/>", {
            class: "no_strong",
            html: this.innerHTML,
        });
    });

    // Store list scrollbar
    $(".sub_cat_name").click(function () {
        $(".sub_cat_name").removeClass("active");
        $(this).addClass("active");
    });

    if ($("body.store_locator").length) {
        setTimeout(function () {
            $(".section-list-store").overlayScrollbars({});
        }, 250);
    }

    if ($("body.in_tunnel").length) {
        setTimeout(function () {
            $(".list-right").overlayScrollbars({});
        }, 650);
    }

    // Swiper verified advices
    if ($(".descrip_avis_ctn").length) {
        new Swiper("#bloc_reviews_slider", {
            slidesPerView: 2.5,
            spaceBetween: 15,
            observeParents: true,
            roundLengths: true,
            observer: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

    // Swiper association products
    if ($(".product_associations_wrapper.gondole").length) {
        // iterate through all swipers with the same id
        $("#productAssociationSwiper").each(function (index) {
            var $this = $(this);
            $this.addClass("s-" + index);
            $this
                .parent()
                .find(".product-associations-pagination")
                .addClass("pagination-" + index);
            $this
                .parent()
                .find(".product-associations-pagination")
                .addClass("product-associations-pagination" + index);
            var tgSwiper = new Swiper(".s-" + index, {
                observeParents: true,
                observer: true,
                uniqueNavElements: false,
                pagination: {
                    el: ".product-associations-pagination" + index,
                    clickable: true,
                    type: "bullets",
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                },
            });
        });
    }

    /**
     * Add 'withUpselling' class on tunnel summary when upselling is active
     * needed for positioning cart push
     */
    if ($(".cart.step_1").length && $(".upselling_alma").length) {
        $("#tunnel_right_col").addClass("withUpselling");
    }

    /* ------------------------------ RECRUTEMENT ------------------------------ */

    var formContactModule = $(".form_contact_module");
    if (formContactModule.length) {
        $(".form_contact_module")
            .parent()
            .parent()
            .addClass("cms_page_recruitment");
    }

    var recruitmentPage = $(".main_wrapper .cms_page_recruitment");
    var recruitmentPageAriane = $(".cms_page_recruitment #cms_page_ariane");
    var btnBack = $(".cms_page_recruitment #cms_page_btn_back");
    if (recruitmentPage.length) {
        recruitmentPageAriane.show();
        btnBack.hide();
    }

    // if a tag is set on module call the function
    if ($(".cms-page-module").find(".tag").length) {
        tagPositionForHomeModule();
    }
    $(".tag")
        .on("mouseenter touchStart", function () {
            $(this).parent().find(".cms-page-column img").addClass("filter_on");
        })
        .on("mouseleave", function () {
            $(this)
                .parent()
                .find(".cms-page-column img")
                .removeClass("filter_on");
        });

    var currentItem = $(this).closest(".main_menu_itm.menu_itm_hover");
    if ($("#maison").hasClass("current")) {
        currentItem.addClass("selected_menu");
    } else {
        currentItem.removeClass("selected_menu");
    }

    /* swiper on satellite univers pages*/
    if ($("#timeline .swiper-slide").length > 0) {
        timelineSwiper = new Swiper("#timeline", {
            slidesPerView: 4.725,
        });
    }

    if ($("#timeline-logo .swiper-slide").length > 0) {
        timelineSwiper = new Swiper("#timeline-logo", {
            slidesPerView: 5,
        });
    }

    if ($("#our_selection .swiper-slide").length > 0) {
        ourSelection = new Swiper("#our_selection", {
            slidesPerView: 4,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }

    if ($("#banner-swiper .swiper-slide").length > 0) {
        selection = new Swiper("#banner-swiper", {
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }

    /* remove the white background on satellite univers pages */
    if ($("body .satellite-page").length > 0) {
        $("body").addClass("universe-pages");

        $(window).on("load scroll", function () {
            const $topWrapper = $("#site_head_wrap");
            const scrollPosition = $(window).scrollTop();

            if (scrollPosition <= 20) {
                $topWrapper.addClass("scroll_bg_transparent");
            } else {
                $topWrapper.removeClass("scroll_bg_transparent");
            }
        });
    }


    $(".switch-btn").each(function () {
        $(this).on("click touch tap", function () {

            // Check if switch-btn instances have the active class
            if (!$(this).hasClass("active")) {
                // Remove all active classes from all switch buttons
                $(".switch-btn").removeClass("active");

                // Add the active class only to the clicked button
                $(this).addClass("active");

                var getBlocName = $(this).attr("data-filter");

                var $correspondingSlider = $(".switch-slider[data-filter='" + getBlocName + "']");

                if ($correspondingSlider.length > 0) {
                    $(".switch-slider").removeClass("active");

                    $correspondingSlider.addClass("active");

                    var newUrl = window.location.href.split('#')[0];

                    if (getBlocName) {
                        newUrl += "#" + getBlocName;
                    }

                    window.history.pushState({}, '', newUrl);
                }
            }
        });
    });

    /* interaction on the shoe svg */
    function handleInteraction(animation) {
        var getTiggerPartName = animation.attr("data-name");
        $(".interactive-btn, .non-active-state").removeClass('active');
        animation.addClass('active');

        $(".shoe-sec").each(function () {
            var getShoePart = $(this).attr("data-name");
            if (getTiggerPartName == getShoePart) {
                $(".shoe-sec").removeClass("show");
                $(this).addClass("show");
            }
        });

        $(".non-active-state").each(function () {
            var getShadePart = $(this).attr("data-name");
            if (getTiggerPartName == getShadePart) {
                $(".non-active-state").removeClass("active");
                $('[data-name=' + getShadePart + ']').addClass('active');
            }
        });
    }

    function removeHandleInteraction() {
        $(".interactive-btn, .non-active-state").removeClass('active');
        $(".shoe-sec").removeClass("show");
    }

    $(".interactive-btn").each(function () {
        $(this).hover(function () {
            handleInteraction($(this));
        }, function () {
            removeHandleInteraction()
        });
    });

    $(".non-active-state").each(function () {
        $(this).hover(function () {
            handleInteraction($(this));
        }, function () {
            removeHandleInteraction()
        });
    });
    /* end interaction */

    if ($('html').is(':lang(en)')) {
        if ($('body.satellite_conseils_entretien')) {
            $('.shoe-sec').addClass('text-en');
        }
    }

    /* Recherche bar on press enter button close search bar */
    $("#search_home").on("keypress", function (event) {
        if (event.which === 13) {
            closeSearch();
        }
    });

    // Init
    if ($(".productSwiper").length) {
        $("body").on("mouseenter touchstart", ".productSwiper", function () {
            if ($(this).hasClass("swiper-initialized")) {
                return;
            }

            // Check if the swiper is in a swiper-wrapper, if so, disable touch move to prevent unwanted behavior on swipe
            let allowTouchMove;
            if ($(this).parents('.swiper-wrapper').length) {
                allowTouchMove = false;
            } else {
                allowTouchMove = true;
            }

            const productSwiper = new Swiper($(this)[0], {
                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: 1,
                navigation: {
                    nextEl: $(this).parents('.item').find(".rollover_rayonsNext")[0],
                    prevEl: $(this).parents('.item').find(".rollover_rayonsPrev")[0],
                },
                allowTouchMove: allowTouchMove,
            });
        });
    }


    // Masquer la carte cadeau si le client est identifié sur le site Global-e

    document.onreadystatechange = function () {
        // Check when the document is fully loaded with global-e
        if (document.readyState == "complete") {
            if (
                typeof GEP != "undefined" &&
                typeof GEP.isOperatedByGlobale != "undefined" &&
                GEP.isOperatedByGlobale !== false
            ) {
                $(".disable_globale").each(function () {
                    if (!$(this).hasClass("disabled")) {
                        $(this).addClass("disabled");
                    }
                });

                var menu_carte_cadeau = $(".main_menu_wrapper .link_giftCard");
                var footer_carte_cadeau = $(
                    "footer .satellites li.menu_giftcard"
                );
                menu_carte_cadeau.hide();
                footer_carte_cadeau.hide();
            }
        }
    };
});

window.onload = function() {
    // Remove the pre-filled input value of inscription nl when the page loads
    if ($('.footerInscription .w-email-input .w-input-element').length) {
        setTimeout(function() {
            $('.footerInscription .w-input.w-email-input input[type="email"]').val('');
        }, 250);
    };
};

function initFPCharacts() {
    if ($(".fp_characts").length) {
        $(".fp_characts").on("mouseenter", function () {
            $(".fp_characts").removeClass("active");
            $(".fp_characts_description").hide();
            var id = $(this).attr("caract_id");
            $(this).addClass("active");
            $(".characts_description_" + id).slideDown("slow");
        });
        $(".fp_characts").on("mouseleave", function () {
            $(".fp_characts").removeClass("active");
            $(".fp_characts_description").hide();
        });
    }
}

/* ------------------------------ PAGE PRODUIT ------------------------------ */
function initTaille() {
    // changement select pointure lorsqu'on clique sur une taille
    $(".product_info_wrapper .choices_list .form_itm.size label").on(
        "click",
        function () {
            var taille = $(this).children().children().html();
            var isAvailable = $(this).hasClass("noStock") ? false : true;
            var shadSelect = $(".shadSelect");

            window.setTimeout(function () {
                shadSelect.removeClass("actif");
            }, 100);

            if (isAvailable === false) {
                $(".prod_listes .size_title").html(
                    '<span class="size_title_content noStock">' +
                    taille +
                    "</span>"
                );
            } else {
                $(".prod_listes .size_title").html(
                    '<span class="size_title_content">' + taille + "</span>"
                );
            }

            if (
                $("#bloc_add_basket #btn_add_cart").data("type") ===
                "add_basket_preco"
            ) {
                $("#bloc_add_basket #fpWrapperBtn").html(
                    Translator.translate("add_basket_preco")
                );
            } else {
                $("#bloc_add_basket #fpWrapperBtn").html(
                    Translator.translate("add_basket")
                );
            }

            $("#bloc_add_basket #fpWrapperBtn").removeClass("noSize");
        }
    );

    $(".product_info_wrapper .choices_list .form_itm .disabled").on(
        "click",
        function () {
            $("#bloc_add_alert").show();
            lightboxAlertStock();
        }
    );

    window.setTimeout(function () {
        if ($("#bloc_add_basket").hasClass("inactiv")) {
            $("#bloc_add_basket #btn_add_cart").val(
                Translator.translate("choose_a_size")
            );
        }
        initPriceBtn();
    }, 300);
}

// Add a class on selected size
function selectedSize() {
    $(".input_check.size").click(function () {
        $(".input_check.size").removeClass("selected");
        $(this).addClass("selected");
    });
}

function rayon_sticky_block() {
    setTimeout(function () {
        // Add class to body when scrolling
        var targetDiv = $(".product_info");
        var targetTop = $(".product_info_wrapper");
        $(window).scroll(function () {
            var windowpos = $(window).scrollTop();
            if (windowpos >= 300) {
                $("#ligne_pointure .choices_list").removeClass("sizesList");
                targetDiv.addClass("scrolling-active");
                targetTop.addClass("padd-bot");
                setTimeout(function () {
                    targetDiv.addClass("appa");
                }, 100);
            } else {
                $("#ligne_pointure .choices_list").addClass("sizesList");
                targetDiv.removeClass("scrolling-active");
                targetTop.removeClass("padd-bot");
                targetDiv.removeClass("appa");
            }
        });
    }, 500);
}

function initPriceBtn() {
    var bloc_add_basket = $("#bloc_add_basket");
    var fpWrapperBtn = $("#fpWrapperBtn");
    var price = $(".page_produit .item_price .price_tag:first-child").html();
    var initialLabel = $("#fpWrapperBtn").data("label");
    var newLabel =
        '<span class="text-price">' +
        initialLabel +
        "</span>" +
        "|" +
        '<span class="price-butt">' +
        price +
        "</span>";

    if (bloc_add_basket.hasClass("inactiv")) {
        fpWrapperBtn.html(Translator.translate("choose_a_size"));
        fpWrapperBtn.addClass("noSize");
    } else {
        fpWrapperBtn.html(initialLabel);
        fpWrapperBtn.removeClass("noSize");
    }

    $(window).scroll(function () {
        if ($(".product_info").hasClass("scrolling-active")) {
            if (bloc_add_basket.hasClass("inactiv")) {
                fpWrapperBtn.html(Translator.translate("choose_a_size"));
                fpWrapperBtn.addClass("noSize");
            } else {
                fpWrapperBtn.html(newLabel);
                fpWrapperBtn.removeClass("noSize");
            }
        } else {
            if (bloc_add_basket.hasClass("inactiv")) {
                fpWrapperBtn.html(Translator.translate("choose_a_size"));
                fpWrapperBtn.addClass("noSize");
            } else {
                fpWrapperBtn.html(initialLabel);
                fpWrapperBtn.removeClass("noSize");
            }
        }
    });
}

function swiperAssociation() {
    new Swiper(".swiper-associations", {
        slidesPerView: 3,
        spaceBetween: 15,
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
        },
        noSwipping: true,
        allowTouchMove: true,
        pagination: {
            el: ".swiper-associations-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".associationsNext",
            prevEl: ".associationsPrev",
        },
        watchOverflow: true,
    });

    setTimeout(function () {
        $(".swiper-associations").addClass("actif");
    }, 500);
}

function swiperArticlesVue() {
    setTimeout(function () {
        new Swiper(".swiper-articles-vues", {
            slidesPerView: 4,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-articles-vues-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".last_seenNext",
                prevEl: ".last_seenPrev",
            },
            watchOverflow: true,
        });
        $(".swiper-articles-vues").addClass("actif");
    }, 500);
}

function floatRightBlock() {
    setTimeout(function () {
        $(".product_info_wrapper").addClass("actif");
    }, 500);
}

/* ------------------------------ ACCOUNT LOGIN ------------------------------ */
// Show lost password form
var afficheLostPassword = function (evt) {
    var signIn = $("#auth");
    var passWd = $("#resetPass");
    var subLogin = $("#subtitleLogin");
    var subPassword = $("#subtitlePassword");

    evt.preventDefault();

    signIn.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            signIn.css("display", "none");

            passWd.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );

    subLogin.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            subLogin.css("display", "none");
            subPassword.css("display", "block").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );
};

// Reload login form (after you submitted email in lost password form)
var reloadBlocLogin = function (evt) {
    var signIn = $("#auth");
    var passWd = $("#resetPass");
    var subLogin = $("#subtitleLogin");
    var subPassword = $("#subtitlePassword");

    evt.preventDefault();

    passWd.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            passWd.css("display", "none");

            signIn.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );

    subPassword.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            subPassword.css("display", "none");

            subLogin.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );
};

function afficheLostPasswordTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }

    $("#is_client_form").hide();
    $("#lost_password_top").css({ display: "block", opacity: 0 }).fadeIn(600);
}

function reloadBlocLoginTop() {
    if ($("#bloc_erreur").is(":visible")) {
        $("#bloc_erreur").css("display", "none");
    }
    subPassword.hide();
    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

// Verify phone number
function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

function openMultiShad(id) {
    $("#" + id).addClass("actif");

    if (id === "lightbox_preco") {
        if (!$("#shad").hasClass("actif")) {
            $("#shad").addClass("actif");
        }
    } else if (id !== "zoomBox") {
        if (!$("#shad").hasClass("actif")) {
            $("#shad").addClass("actif");
        }

        $("#shad").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {
        $("#zoom_box_wrapper .img_large").on("click", function () {
            closeMultiShad("zoomBox");
        });
    }

    if (id === "popup_savoir_plus") {
        $("#popup_savoir_plus").css("display", "block");
        $("#shad").css("display", "block");
    }

    if (id === "lightboxOrderGiftCard" || id === "sendwishlistbox") {
        $("#shad").addClass("actif");
    }

    if (id === "alertStockBox") {
        setTimeout(function () {
            new Swiper(".associated-product-lightbox", {
                slidesPerView: 2,
                grid: {
                    fill: "row",
                    rows: 2,
                },
                spaceBetween: 15,
                pagination: {
                    el: ".associated-product-lightbox-pagination",
                    clickable: true,
                },
            });
            $(".associated-product-lightbox").addClass("actif");
        }, 500);
    }

    if (id === "sizeguideBox") {
        $("body.product_page").addClass("no_hoverflow");
    }
}

function openSmallBasketExpress(id) {
    $("#show_top_cart").addClass("noDisplay");
    $(".smallbasketExpress").removeClass("actif");

    setTimeout(function () {
        $("#" + id).addClass("actif");
    }, 300);
}

function closeSmallBasketExpress(id) {
    $("#" + id).removeClass("actif");
}

function closeMultiShad(id) {
    $("body").css("position", "");
    $("#" + id).removeClass("actif");
    $("#shad").removeClass("actif");
    $(".shad").removeClass("actif").removeClass("white");

    if (id === "exit_overlay_OVI") {
        $("#exit_overlay_OVI").hide();
        $("#shad").hide();
    }

    if (id === "ligthbox_send_store_information") {
        var report = $("#ligthbox_send_store_information .report");

        report.html("");
        report.slideUp();
        send_store_loader("hide");
    }

    if (id === "alertStockBox") {
        setTimeout(() => {
            $(".bloc_add_alert_confirmation").hide();
            $(".bloc_add_alert_form").show();
            if (wShop.$refs.wAlertestockForm) {
                wShop.$refs.wAlertestockForm.reloadBloc += 1;
            }
        }, 1000);
    }

    // Pin lightbox
    if ($(".tag").length) {
        $(".lightbox .close").click(function () {
            const closeBtn = $(this).closest(".lightbox");
            closeBtn.removeClass("actif");
        });

        $("#shad").on("click", function () {
            $(".tag + .lightbox").removeClass("actif");
        });
    }

    if (id === "sizeguideBox") {
        $("body.product_page").removeClass("no_hoverflow");
    }
}

function btnLoaderUnloading(btn) {
    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {
        setTimeout(function () {
            loader.removeAttr("style").parent().removeClass("loading");
        }, 200);
    }
}

/* ------------------------------ STORE LOCATOR ------------------------------ */

// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";
    var ligneListe =
        '<div class="elem_list_contents">' + '<div class="nom_store">';
    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse.toLowerCase() +
        "</span>" +
        '<span class="ville_store">' +
        cp +
        " " +
        ville.charAt(0).toUpperCase() +
        ville.slice(1).toLowerCase() +
        " - " +
        '<span class="pays_store">' +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a>";

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }
    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="triggerStoreDatalayerPush(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
            + '</div>';
    }

    ligneListe += "</div>" + "</div>" + "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/distrib_he.svg";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(39, 53),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/indic_carte_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse.toLowerCase() +
        "<br />" +
        cp +
        " " +
        ville.toLowerCase() +
        "<br />" +
        pays.toLowerCase() +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;

    markersArray.push(marqueur);

    return marqueur;
}

function addToMyFavorite(
    magasin_id,
    client_id,
    favorite,
    info_horaire_today,
    currently_open,
    horaire_today,
    class_horaire_today,
    stock,
    stockStatus
) {
    event.preventDefault();
    if (client_id == 0) {
        var connect_link = path_relative_root + create_link("connexion_login");
        alert(
            '<div class="box_txt_wishlist selected_mag"><h2>' +
            Translator.translate("add_store_login_error_title") +
            "</h2><br>" +
            Translator.translate("add_store_login_error_text") +
            '<br><button class="button primary" onclick="favStoreRedirectLogin()">' +
            Translator.translate("vgt_connexion") +
            " </button></div>"
        );
    } else {
        var act = "add";

        $.ajax({
            url: path_relative_root + "ajax_manage_favorite_store.php",
            type: "post",
            dataType: "html",
            data:
                "act=" +
                act +
                "&magasin_id=" +
                magasin_id +
                "&client_id=" +
                client_id,
            success: function (response) {
                response = JSON.parse(response);
                var getCustomerFavoriteStore = document.querySelector(
                    "#customerFavoriteStore"
                );
                var customerFavoriteStore = false;
                if (getCustomerFavoriteStore) {
                    customerFavoriteStore = customerFavoriteStore.value;
                }
                var shopName = document.querySelector(".mag_name_fav");
                var shopStock = document.querySelector(".mag_stock_fav");
                var shopHours = document.querySelector(".mag_hours");
                var shopTown = document.querySelectorAll(".mag_ville");
                var shopStreet = document.querySelector(".mag_rue");
                var shopCountry = document.querySelector(".mag_pays");
                var shopCp = document.querySelector(".mag_cp");

                var noStoreSelected =
                    document.querySelector("#no_store_selected");
                var noStoreSelectedHidden = document.querySelector(
                    "#no_store_selected_hidden"
                );

                var shopList = document.querySelector(".liste_distributeur");

                var customerHeartFav = document.querySelector(
                    "#heartFav-" + magasin_id
                );
                var allHeartFav = document.querySelectorAll(".heartFav");

                var currentFavStore =
                    document.querySelector("#currentFavStore");
                var hiddenProductFavStore = document.querySelector(
                    "#hiddenProductFavStore"
                );
                var noFavShop = document.querySelector("#noFavShop");

                if (allHeartFav) {
                    for (var i = 0; i < allHeartFav.length; i++) {
                        allHeartFav[i].className = "heartFav";
                    }
                }
                if (hiddenProductFavStore && noFavShop) {
                    noFavShop.style.display = "none";
                    hiddenProductFavStore.style.display = "flex";
                }

                if (customerHeartFav) {
                    customerHeartFav.className = "heartFav";
                    customerHeartFav.className = "selected";
                }

                if (shopList) {
                    shopList.className += " withfav";
                }
                if (
                    noStoreSelected &&
                    noStoreSelectedHidden &&
                    !currentFavStore
                ) {
                    noStoreSelected.style.display = "none";
                    noStoreSelectedHidden.style.display = "block";
                }

                if (shopName) {
                    shopName.innerHTML = response.store.magasin_nom;
                }

                if (shopTown) {
                    for (var i = 0; i < shopTown.length; i++) {
                        var town = shopTown[i];
                        town.innerHTML = response.store.magasin_ville;
                    }
                }
                if (shopStreet) {
                    shopStreet.innerHTML = response.store.magasin_adresse;
                }
                if (shopCp) {
                    shopCp.innerHTML = response.store.magasin_cp;
                }
                if (shopCountry) {
                    shopCountry.innerHTML = response.store.magasin_pays;
                }
                if (stock != "null") {
                    shopStock.innerHTML = Translator.translate(
                        stock.slice(0, -3)
                    );
                    shopStock.setAttribute("data-status", stockStatus);
                }

                if (shopHours) {
                    if (
                        (info_horaire_today !== "" && currently_open) ||
                        (!currently_open && horaire_today)
                    ) {
                        shopHours.innerHTML =
                            '<span class="horaire_today"><span class="puce ' +
                            unescape(class_horaire_today) +
                            '"></span> ' +
                            unescape(info_horaire_today) +
                            "</span>";
                    } else {
                        shopHours.innerHTML = "";
                    }
                }

                var storeClient = document.getElementById(
                    "favShop-" + magasin_id
                );
                var allStore =
                    document.getElementsByClassName("choose_fav_label");
                var countAllStore = allStore.length;

                for (var i = 0; i < countAllStore; i++) {
                    allStore[i].innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if (response.status == "add") {
                    storeClient.innerHTML =
                        '<span class="favorite_store_js">' +
                        Translator.translate("actual_favorite_store") +
                        "</span>";
                } else if (response.status == "delete") {
                    storeClient.innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if ($('#pdvBox').hasClass("deployed") && act == "add") {
                    $('#pdvBox').removeClass("deployed");
                    location.reload();
                }
            },
        });
    }
    return false;
}

// popup PDV
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + create_link("ajax_search_shop"),
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shad.modal_shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                // Show modbox and make sure closing routine won't fire more than once

                $(shade).off("click", closepdv);
                $(modbox).addClass("deployed");
                $(shade).addClass("actif");
                $(shade).on("click", closepdv);
                $("#pdv_load").removeClass("loading");
            }
        },
    });
}

/* ------------------------------ FAVORITE STORE ------------------------------ */
function addToMyFavorites() {
    var is_favorite_elem = document.getElementById("is_favorite");

    var self = $(this);
    var magasin_id = self.attr("data-magasinid");
    var client_id = self.attr("data-clientid");
    var action = self.attr("data-action");

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == "destroy") {
        var act = "del";
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == "1") {
            var act = "del";
        } else {
            var act = "add";
        }
    }

    $.ajax({
        url: path_relative_root + "ajax_manage_favorite_store_detail.php",
        type: "post",
        dataType: "html",
        data: "act=" + act + "&magasin_id=" + magasin_id,
        success: function (response) {
            if (response) {
                // Destruction du bloc
                if (action == "destroy") {
                    self.slideUp("slow", function () {
                        self.remove();
                    });
                } else {
                    if (act == "add") {
                        var libelle =
                            "<span>" +
                            Translator.translate("delete_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle =
                            "<span>" +
                            Translator.translate("add_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        },
    });
}

/**
 * After ajax called in basket (also called in _app)
 */
function updateCartElements() {
    var cartArticles = $(".cart_main_table.basket");
    var cartWishlist = $(".cart_main_table.wishlist");
    var AssocPosition = cartWishlist.length ? cartWishlist : cartArticles;
    var cartAssocSwiper = $(".cartAssocSwiper");
    var cartPush = $(".push_container");

    // Placement of associations just after products/wishlist
    $(".cartAssociations").insertAfter(AssocPosition);
    // Placement of push right after cart right summary
    cartPush.insertAfter($("#right_col_sticky"));

    /**
     * Cart associations slider init
     */
    if (cartAssocSwiper.length) {
        new Swiper(".cartAssocSwiper", {
            slidesPerView: 3.4,
            spaceBetween: 15,
            pagination: {
                el: ".cartAssocPagination",
                clickable: true,
                type: "bullets",
            },
        });

        setTimeout(function () {
            $(".cartAssociations").addClass("appear");
        }, 300);
    }
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

/**
 * Toggle product details in cart right column
 */
$(".total_produit").on("click", function () {
    $(this).toggleClass("open");
    $(".total_produit_dropdown").slideToggle();
});

// Fonction utilisée quand l'utilisateur indique un lieu
function geocodeAdresse() {
    // On affiche le loader
    displayLoader("hide");

    var adresse = "";

    adresse = document.getElementById("search_cp").value.trim();

    // Cas special pour nancy il nous envoie aux US!!!
    if (search_exceptions.includes(adresse)) {
        adresse += " france";
    }

    if (zipcode_rex.test(adresse)) {
        adresse += ", France";

        document.getElementById("search_cp").value += ", France";
    }

    if (geocodeur != "undefined") {
        geocodeur = new google.maps.Geocoder();
    }

    if (adresse == "") {
        adresse = get("search_cp");
        if (typeof adresse == "undefined") {
            adresse = "";
        }
    }

    if (
        document.getElementById("produit_id") &&
        document.getElementById("produit_id").value !== ""
    ) {
        //adresse += '&produit_id=' + encodeURIComponent(document.getElementById('produit_id').value);
    }

    geocodeur.geocode(
        {
            address: adresse,
        },
        function (results, status) {
            $(".txt_alert#error_loc").remove();
            // Si l'utilisateur ne saisit rien on se remet à la position initiale (on remet la premiere carte et tous les magasins)
            if (adresse === "") {
                if ($("#retailers_map").length) {
                    initSearch();
                    clusterer = new MarkerClusterer(
                        maCarte,
                        markers,
                        mcOptions
                    );

                    boundChanched(maCarte, markersArray);
                    markers = [];
                }

                // On va rechercher tous les magasins
                rechercheProximite();
            } else if (type_call == "select_pays") {
                rechercheProximite();
            } else if (status == google.maps.GeocoderStatus.OK) {
                rechercheProximite(results[0]);
            } else {
                displayLoader("show");
                let error =
                    "<div class='txt_alert' id='error_loc'>" +
                    adresse +
                    " " +
                    Translator.translate("txt_introuvable") +
                    "</div>";
                $(error).insertAfter($("div.wrap_fields"));
            }
        }
    );
}

/* ------------------------------ TAG ------------------------------ */

// Add class on module tags to improve lightbox positioning on hover
function tagPositionForHomeModule() {
    // stores the tag container in each module in an array
    var moduleArray = [
        {
            module: ".cms-page-module.image-module.images2",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.slider-cover-module.cover-look",
            wrapper: ".cms-page-columns-container",
        },
        {
            module: ".cms-page-module.text-image-4",
            wrapper: ".cms-page-columns-container",
        },
        {
            module: ".cms-page-module.image-module.images3",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.image-module.images4",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.image-module.images5",
            wrapper: ".blocImage",
        },
    ];

    // iteration in each module
    moduleArray.forEach(function (obj) {
        if ($(obj.module).length) {
            var imgWrapper = $(obj.module).find(obj.wrapper);

            var centerPos = imgWrapper.width() / 2;
            var middlePos = imgWrapper.height() / 2;

            // set the tag new position in each module
            imgWrapper.find(".tag").each(function () {
                var tagCenterPos = 7 + parseInt($(this).css("left"));
                var tagMiddlePos = 7 + parseInt($(this).css("top"));

                if (tagCenterPos >= centerPos) {
                    $(this).removeClass("leftPos").addClass("rightPos");
                } else {
                    $(this).removeClass("rightPos").addClass("leftPos");
                }

                if (tagMiddlePos >= middlePos) {
                    $(this).removeClass("topPos").addClass("bottomPos");
                } else {
                    $(this).removeClass("bottomPos").addClass("topPos");
                }
            });
        }
    });
}

/* Recherche bar on press enter button open search bar */
function activateSearchBar() {
    $("#trigger_recherche").click();
}

function mySubmit(pType) {
    $(".cartLoader").addClass("loading");
    var form = document.getElementById("formPanier");

    form.action = create_link("order_basket");

    setTimeout(function () {
        form.submit();
    }, 500);
}

/**
 * Add new payement card in account page
 */
$(document).on(
    "click touch",
    ".account_payment_options .addNewCard",
    function () {
        $(".wrapper_form").removeClass("displayed");
        $("#psp_form").addClass("displayed");
    }
);

function deleteItemFromCart(id, qty) {
    if (
        typeof dataLayer_v3 !== "undefined" &&
        dataLayer_v3 !== "" &&
        dataLayer_v3 !== null &&
        id !== null &&
        id !== undefined
    ) {
        DataLayerTrigger.cart_product_action(
            dl_v3_impressions,
            dlv3_action_fields,
            id,
            "remove_from_cart",
            qty
        );
    }
}

// Masquer la carte cadeau si le client est identifié sur le site Global-e
$(function () {
    document.onreadystatechange = function () {
        // Check when the document is fully loaded with global-e
        if (document.readyState == "complete") {
            if (
                typeof GEP != "undefined" &&
                typeof GEP.isOperatedByGlobale != "undefined" &&
                GEP.isOperatedByGlobale !== false
            ) {
                $(".disable_globale").each(function () {
                    if (!$(this).hasClass("disabled")) {
                        $(this).addClass("disabled");
                    }
                });

                var menu_carte_cadeau = $(".main_menu_wrapper .link_giftCard");
                var footer_carte_cadeau = $(
                    "footer .satellites li.menu_giftcard"
                );
                menu_carte_cadeau.hide();
                footer_carte_cadeau.hide();
            }
        }
    };

    // Vérifie que la page contient bien la classe désirée
    if (document.body.classList.contains("universe-pages")) {
        showElementOfUrlId();
    }
});

function showElementOfUrlId() {
    const url = window.location.href;
    const urlSegments = url.split("#")[1];

    if (urlSegments) {
        $("html, body").animate(
            {
                scrollTop:
                    $(".switch-controller").offset().top -
                    $("#site_head_wrap").outerHeight() - 40,
            },
            400
        );
        addActiveClass(`[data-filter="${urlSegments}"]`, urlSegments);
    } else if (url.includes("shoe_care")) {
        addActiveClass(`[data-filter="the-golden-rules"]`, "the-golden-rules");
    } else {
        addActiveClass(`[data-filter="les-regles-d-or"]`, "les-regles-d-or");
    }
    window.onload = showElementOfUrlId;
}

function addActiveClass(selector, id) {
    const switchBtn = document.getElementById(id);

    if (switchBtn) {
        $(".switch-btn").removeClass("active");

        switchBtn.classList.add("active");
        const switchSlider = document.querySelector(
            '.switch-slider[data-filter="' + id + '"]'
        );
        $(".switch-slider").removeClass("active");
        switchSlider.classList.add("active");
    }
}

function fadeInShowClass(scrollBarPosition) {
    if (window.pageYOffset >= scrollBarPosition) {
        $('.svg-box').addClass('fadeInShow')
    } else {
        $('.svg-box').removeClass('fadeInShow')
    }
}

if ($('.satellite_conseils_entretien')) {
    window.addEventListener('scroll', function () {
        if (window.innerWidth >= 1024 && window.innerWidth <= 1400) {
            fadeInShowClass(650);
        } else if (window.innerWidth > 1400 && window.innerWidth <= 1600) {
            fadeInShowClass(850);
        } else if (window.innerWidth > 1600) {
            fadeInShowClass(1000);
        }
    });
}

function truncatePhrase(phrase, maxLength) {
    var isTruncated = false;
    if (phrase.length > maxLength) {
        isTruncated = true;
        phrase = phrase.substring(0, maxLength) + "...";
    }
    return {text: phrase, truncated: isTruncated};
}

if ($('.product-link').length) {
    $('.product-link').each(function() {
        var maxLength = 28;
        var sub_title_text = $(this).find('.sub_title').text();
        var sstitre_text = $(this).find('.sstitre').text();

        var result_sub_title = truncatePhrase(sub_title_text, maxLength);
        var truncated_sub_title = result_sub_title.text;
        var is_sub_title_truncated = result_sub_title.truncated;

        var result_sstitre = truncatePhrase(sstitre_text, maxLength);
        var truncated_sstitre = result_sstitre.text;
        var is_sstitre_truncated = result_sstitre.truncated;

        $(this).find(".sub_title").text(truncated_sub_title);
        $(this).find(".sstitre").text(truncated_sstitre);

        if (is_sub_title_truncated) {
            $(this).find(".sub_title").attr('title', sub_title_text);
        }

        if (is_sstitre_truncated) {
            $(this).find(".sstitre").attr('title', sstitre_text);
        }
    });
}


// Get product data
function getProductDetail(productId) {

    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + productId);

    var pcolor = form.getValue('idCouleur');
    let val_color = form.getValue(pcolor);

    let psize = form.getValue('idTaille');
    let val_size = form.getValue(psize);

    let data = {
        produitId: productId,
        colorId : val_color,
        sizeId : val_size,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_product_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof(res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

function openAlerteStock(id) {
    if (!$('.bloc_add_alert_' + id + ' .alert_stock').hasClass('show')) {
        $('.bloc_add_alert_' + id + ' .alert_stock').addClass('show');
        $('.bloc_add_alert_' + id + ' .bloc_add_alert_form').addClass('show');
        $('.bloc_add_alert_' + id + ' .alert_return').show();
        $('.bloc_add_alert_' + id + ' .bloc_add_alert_form').show();
    }
}